<template>
  <div class="main-box">
    <div class="flex-row-start-between">
      <a-button class="btn-create-access" @click="onCreateTenant"
                :disabled="this.listOptions.loading">Create New Tenant
      </a-button>
      <a-button class="btn-create-access" @click="onRefreshList"
                :disabled="this.listOptions.loading">Refresh List
      </a-button>
    </div>
    <a-table
      :data="listOptions.noData ? [] : listData"
      :bordered="listOptions.border"
      :hoverable="listOptions.hover"
      :stripe="listOptions.stripe"
      :loading="listOptions.loading"
      :show-header="listOptions.tableHeader"
      :row-selection="listOptions.checkbox ? listSelection : undefined"
      :scroll="listOptions.scroll"
    >
      <template #columns>
        <a-table-column fixed="left" width="120px">
          <template #title><span class="text-head">Tenant ID</span></template>
          <template #cell="{ record }">
            <!--            <span class="text-grid">{{ record.tenantId }}</span>-->
            <a-link @click="$router.push({path:`/accounts/${record.accountId}/tenants/${record.tenantId}`})">
              {{ record.tenantId }}
            </a-link>
          </template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Project Name</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.projectName }}</span></template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Project Desc</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.projectDesc }}</span></template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Create Time</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.cdate }}</span></template>
        </a-table-column>
        <a-table-column>
          <template #title><span class="text-head">Modify Time</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.mdate }}</span></template>
        </a-table-column>
        <!--
        <a-table-column fixed="right" width="81px">
          <template #title><span class="text-head">Active</span></template>
          <template #cell="{ record }">
            <a-switch v-model="record.active" class="my-switch" @change="onAccessActiveChange(record)">
              <template #checked><span class="text-switch">ON</span></template>
              <template #unchecked><span class="text-switch">OFF</span></template>
            </a-switch>
          </template>
        </a-table-column>
        <a-table-column fixed="right" width="73px">
          <template #title><span class="text-head">Delete</span></template>
          <template #cell="{ record }">
            <a-link style="padding: 6px;" @click="onAccessDelete(record)">
              <icon-delete :style="{fontSize:'16px',color:'#000000'}" />
            </a-link>
          </template>
        </a-table-column>
        -->
        <!--        <a-table-column fixed="right" width="60px">-->
        <a-table-column fixed="right">
          <template #title><span class="text-head">Operator</span></template>
          <template #cell="{ record }">
            <a-link style="padding: 6px;margin-left: 5px;" @click="onTenantView(record)">View</a-link>
            <a-link style="padding: 6px;margin-left: 5px;" @click="onTenantEdit(record)">Edit</a-link>
            <a-link style="padding: 6px;margin-left: 5px;" @click="onTenantDelete(record)">
              <icon-delete :style="{fontSize:'16px',color:'#000000'}" />
            </a-link>
          </template>
        </a-table-column>
      </template>
    </a-table>

    <!-- 编辑项目信息弹窗 begin -->
    <tenant-editor @tenant-created="onTenantCreated" @tenant-updated="onTenantUpdated"
                   :envId="envId" ref="tenantEditor"></tenant-editor>
    <!-- 编辑项目信息弹窗 end -->
  </div>
</template>
<script>
import { queryTenants, deleteTenant } from "@/api/tenant";
// import { IconDelete, IconRefresh } from "@arco-design/web-vue/es/icon";
import { IconDelete } from "@arco-design/web-vue/es/icon";
import TenantEditor from "@/components/TenantEditor";

export default {
  components: {
    IconDelete, TenantEditor
  },
  data() {
    return {
      envId: "",
      accountId: null,
      listOptions: {
        border: true,
        borderCell: false,
        hover: true,
        stripe: false,
        checkbox: false,
        loading: true,
        tableHeader: true,
        noData: false,
        scroll: {
          x: 1200
          // y: 1000
        }
      },
      listSelection: {
        type: "checkbox",
        showCheckedAll: true
      },
      listData: [],
      createOptions: {
        visible: false,
        editable: true,
        loading: false,
        tenantId: "",
        projectName: "",
        projectDesc: ""
      }
    };
  },
  async created() {
    this.accountId = this.$route.params.accountId;
    this.listData = await queryTenants(this.accountId);
    this.listOptions.loading = false;
  },
  methods: {
    async onRefreshList() {
      this.listOptions.loading = true;
      this.listData = await queryTenants(this.accountId);
      this.listOptions.loading = false;
    },
    onTenantUpdated(tenant) {
      console.log("onTenantUpdated:", JSON.stringify(tenant));
      const findArr = this.listData.filter(it => it.tenantId === tenant.tenantId);
      if (findArr.length >= 0) {
        this.copyFields(findArr[0], tenant);
      }
    },
    onTenantCreated(newTenant) {
      console.log("onTenantCreated:", JSON.stringify(newTenant));
      this.listData.push(newTenant);
    },
    onAccessSecretShow(record) {
      // record.secretShow = true;
      // console.log("onAccessSecretShow:", record.accessKey, record.secretShow);
      this.$refs.tenantEditor.openByView(record);
    },
    onCreateTenant() {
      console.log("onCreateTenant:");
      this.$refs.tenantEditor.openByCreate({ accountId: this.accountId });
    },
    onTenantView(record) {
      console.log("onTenantView:");
      this.$refs.tenantEditor.openByView({ ...record, accountId: this.accountId });
    },
    onTenantEdit(record) {
      console.log("onTenantEdit:");
      this.$refs.tenantEditor.openByEdit({ ...record, accountId: this.accountId });
    },
    async onTenantDelete(record) {
      const deleted = await deleteTenant(record.tenantId);
      if (deleted) {
        this.listData = this.listData.filter(it => it.tenantId !== record.tenantId);
      }
    }
  }
};
</script>
<style lang="scss" scoped>

.main-box {
  border-radius: 16px;
  background: #FFFFFF;

  padding: 16px 21px 16px 21px;
}

.btn-show-secret {
  font-size: 14px;
  font-family: Helvetica;
  color: #F97846;
  line-height: 22px;
}

.text-grid {
  font-size: 14px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 22px;
}

.text-head {
  font-size: 14px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 22px;
  text-shadow: 1px 0px 0px #F2F3F5;
}

.my-switch {
  background: #F97846;
}

.text-switch {
  font-size: 12px;
  font-family: Helvetica;
  color: #FFFFFF;
  line-height: 20px;
}

.btn-create-access {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;

  margin-bottom: 16px;
}

</style>
