<template>
  <div>
    <a-modal v-model:visible="visible" ok-text="OK" unmountOnClose
             :ok-loading="loading" @before-ok="beforeCreate" @before-cancel="beforeCancel"
             @ok="confirmCreate" @cancel="cancelCreate" :hide-cancel="true">
      <template #title><span class="create-title">Tenant</span></template>
      <div class="flex-column-start-center" style="padding: 0 4px;">
        <!--
        <div class="flex-row-start-start">
          <div class="modal-icon-box">
            <icon-exclamation-circle class="modal-icon" />
          </div>
          <div class="modal-icon-text">{{ warningText }}</div>
        </div>
        -->
        <div class="flex-row-start-center" style="margin-top: 24px;">
          <span class="text-key">Tenant ID</span>
          <a-input :style="inputStyle" class="text-input" placeholder="Tenant ID"
                   v-model="form.tenantId" :disabled="!editable || state!='create'">
            <template #suffix>
              <a-button class="copy-box" @click="copyAk">
                <icon-copy />
              </a-button>
            </template>
          </a-input>
        </div>
        <div class="flex-row-start-center" style="margin-top: 16px;">
          <span class="text-key">Project Name</span>
          <a-input :style="inputStyle" class="text-input" placeholder="Project Name"
                   v-model="form.projectName" :disabled="!editable">
            <template #suffix>
              <a-button class="copy-box" @click="copySk">
                <icon-copy />
              </a-button>
            </template>
          </a-input>
        </div>
        <div class="flex-row-start-center" style="margin-top: 16px;">
          <span class="text-key">Project Desc</span>
          <a-input :style="inputStyle" class="text-input" placeholder="Project Desc"
                   v-model="form.projectDesc" :disabled="!editable">
            <template #suffix>
              <a-button class="copy-box" @click="copySk">
                <icon-copy />
              </a-button>
            </template>
          </a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { IconExclamationCircle, IconCopy } from "@arco-design/web-vue/es/icon";
import { copyToClipboard } from "@/utils/clipUtil";
import request from "@/utils/request";
import { createTenant, updateTenant } from "@/api/tenant";

export default {
  components: {
    IconExclamationCircle, IconCopy
  },
  props: {
    envId: { // 'staging'|'prod'
      type: String,
      required: true
    }
  },
  data() {
    return {
      warningText: "This is the ONLY time that the secret keys can be viewed. You cannot recover them later. However, you can create new access keys at any time",
      visible: false,
      editable: true,
      loading: false,
      state: null, // create|view|edit

      inputStyle: { width: "352px", paddingRight: "0" },
      form: {
        accountId: "",
        tenantId: "",
        projectName: "",
        projectDesc: ""
      }
    };
  },
  created() {
    console.log("AccessEditor created! envId=", this.envId);
  },
  methods: {
    openByCreate(record) {
      console.log("openByCreate:", JSON.stringify(record));
      this.state = "create";
      this.copyFields(record, this.form);
      this.visible = true;
      this.editable = true;
      this.loading = false;
    },
    openByView(record) {
      console.log("openByView:", JSON.stringify(record));
      this.state = "view";
      this.assignFields(record, this.form);
      this.visible = true;
      this.editable = false;
      this.loading = false;
    },
    openByEdit(record) {
      console.log("openByEdit:", JSON.stringify(record));
      this.state = "edit";
      this.assignFields(record, this.form);
      this.visible = true;
      this.editable = true;
      this.loading = false;
    },
    copyAk() {
      console.log("copyAk:");
      copyToClipboard(this.accessKey);
      this.$message.info("Access key has been copied to the clipboard");
    },
    copySk() {
      console.log("copySk:");
      copyToClipboard(this.secretKey);
      this.$message.info("Secret key has been copied to the clipboard");
    },
    beforeCancel() {
      return !this.loading;
    },
    async beforeCreate(done) {
      if (this.state === "view") {
        done();
        return;
      }
      console.log("beforeCreate: state=", this.state);
      this.editable = false;
      this.loading = true;
      // await new Promise((resolve, reject) => setTimeout(resolve, 2500));
      if (this.state == "create") {
        const data = await createTenant(this.form);
        if (data) {
          this.$emit("tenantCreated", data);
        }
      } else if (this.state == "edit") {
        const data = await updateTenant(this.form);
        if (data) {
          this.$emit("tenantUpdated", data);
        }
      }
      done();
      this.visible = false;
      this.loading = false;
    },
    confirmCreate() {
    },
    cancelCreate() {
      console.log("cancelCreate:");
    }
  }
};
</script>
<style lang="scss" scoped>

.text-input {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;
}

.create-title {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}

.text-key {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;

  box-sizing: content-box;
  min-width: 110px;
  padding-right: 14px;
}

.copy-box {
  padding: 4px 12px 4px 12px;
}

.modal-icon-box {
  width: 32px;
  height: 32px;
  box-sizing: content-box;
  padding: 0 24px 0 0;
}

.modal-icon {
  font-size: 32px;
  color: #F97846;
}

.modal-icon-text {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}
</style>
